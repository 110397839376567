<template>
  <v-container fluid>
    <v-tab-item class="pb-12">
      <div class="text-center display-1 font-weight-normal mb-6">
        Vamos começar preenchendo as informações básicas
      </div>
      <v-row class="mx-auto">
        <v-col cols="12">
          <v-alert
            dense
            outlined
            type="info"
            color="blue"
            class="mb-4 py-0 px-1"
          >
            NÃO são utilizadas as TIs: 10 a 29, 70 a 92, 94, 95, 97 a 99.
          </v-alert>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              id="titulo-comparativo"
              label="Título"
              outlined
              v-model="campos.titulo"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        class="mx-auto"
        id="data-imobilizacao-comparativo"
      >
        <v-col cols="5">
          <input-date
            label="Data Imobilização"
            :selectedDate="campos.dataImobInicio"
            :rules="{
              required_if:
                campos.dataImobFim != null && campos.dataImobFim.length > 0,
              min: 10
            }"
            @dataSelected="(data) => (campos.dataImobInicio = data)"
          />
        </v-col>
        <v-col
          class="text-center pt-6"
          style="min-width: 60px !important"
          cols="1"
        >
          <span style="color: rgba(0, 0, 0, 0.54); font-size: 15px">até</span>
        </v-col>
        <v-col cols="5">
          <input-date
            label="Data Imobilização"
            :selectedDate="campos.dataImobFim"
            :rules="{
              required_if:
                campos.dataImobInicio != null &&
                campos.dataImobInicio.length > 0,
              min: 10
            }"
            @dataSelected="(data) => (campos.dataImobFim = data)"
          />
        </v-col>
      </v-row>
      <div id="group-check-comparativo">
        <v-row class="mx-auto spacing-checkbox-sig-r">
          <v-col
            class="pb-0"
            cols="12"
          >
            <v-checkbox
              class="mt-0"
              v-model="campos.anual"
              label="Comparar por ano"
              checked
            />
          </v-col>
        </v-row>
        <v-row class="mx-auto spacing-checkbox-sig-r">
          <v-col
            class="pb-0"
            cols="12"
          >
            <v-checkbox
              class="mt-0 pt-0"
              v-model="campos.comparaTi"
              label="Comparar por TI"
            />
          </v-col>
        </v-row>
        <v-row class="mx-auto spacing-checkbox-sig-r">
          <v-col cols="12">
            <v-checkbox
              class="mt-0 pt-0"
              v-model="campos.sobraFisica"
              label="Incluir Todas as Situações Contábeis (SITCONT)"
            />
          </v-col>
        </v-row>
      </div>
    </v-tab-item>
    <v-tab-item class="pb-0">
      <div class="text-center display-1 font-weight-normal mb-6">
        Qual o tipo do SIG-R?
      </div>
      <v-row
        align="center"
        class="mx-auto"
        id="group-tipo-comparativo"
      >
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-radio-group
              v-model="campos.bdgd"
              :error-messages="errors"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                label="SIG-R Completa"
                :value="false"
              />
              <v-radio
                label="Apenas BDGD"
                :value="true"
              />
            </v-radio-group>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="mx-auto"
        id="data-base-bdgd-ais-comparativo"
      >
        <v-col cols="5">
          <input-month
            label="Data Base BDGD"
            :selectedMonth="campos.monthBdgd"
            @monthSelected="(month) => (campos.monthBdgd = month)"
          />
        </v-col>
        <v-col cols="1" />
        <v-col cols="5">
          <select-bdgd-version
            label="Versão BDGD"
            required
            :companyId="empresa.id"
            :month="campos.monthBdgd"
            @bdgdVersionSelected="
              (bdgdVersionId) => (campos.bdgdVersionId = bdgdVersionId)
            "
          />
        </v-col>
      </v-row>
      <div
        class="text-center display-1 font-weight-normal mb-6"
        v-if="!campos.bdgd"
      >
        Qual base contábil será comparada com a BDGD?
      </div>
      <v-row
        align="center"
        class="mx-auto"
        id="tipo-base-contabil"
        v-if="!campos.bdgd"
      >
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-radio-group
              v-model="campos.tipoBaseContabil"
              :error-messages="errors"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                label="AIS"
                value="AIS"
              />
              <v-radio
                label="Laudo"
                value="LAUDO"
              />
            </v-radio-group>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="mx-auto"
        v-if="!campos.bdgd && campos.tipoBaseContabil === 'AIS'"
      >
        <v-col cols="5">
          <input-month
            label="Data Base AIS"
            :selectedMonth="campos.monthAis"
            @monthSelected="(month) => (campos.monthAis = month)"
          />
        </v-col>
        <v-col cols="7" />
      </v-row>
      <v-row
        align="center"
        class="mx-auto"
        v-if="!campos.bdgd && campos.tipoBaseContabil === 'LAUDO'"
      >
        <v-col cols="5">
          <input-month
            label="Data Base Laudo"
            :selectedMonth="campos.monthLaudo"
            @monthSelected="(month) => (campos.monthLaudo = month)"
          />
        </v-col>
        <v-col cols="7" />
      </v-row>
    </v-tab-item>
    <v-tab-item class="pb-12">
      <div class="text-center display-1 font-weight-normal mb-6">
        Para finalizar, preencha as TUCs e ODIs para comparação
      </div>
      <v-row
        align="center"
        class="mx-auto"
        id="tucs-comparativo"
      >
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-select
              :error-messages="errors"
              v-model="campos.tucsSelected"
              :items="campos.tucs"
              item-value="id"
              item-text="nome"
              label="TUCs"
              multiple
              outlined
              chips
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="mx-auto"
        id="odis-comparativo"
      >
        <v-col cols="12">
          <v-textarea
            label="ODis"
            placeholder="Insira os códigos das ODIs, um código por linha"
            rows="5"
            no-resize
            clearable
            clear-icon="mdi-close-circle"
            v-model="campos.odis"
          />
        </v-col>
      </v-row>
    </v-tab-item>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import AisService from '@/services/AisService.js';
import LaudosService from '@/services/LaudosService.js';
import BdgdVersionsService from '@/services/BdgdVersionsService.js';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  mixins: [dateFormatterMixins],

  components: {
    SelectBdgdVersion: () =>
      import('@/components/general/SelectBdgdVersion.vue'),
    InputMonth: () => import('@/components/general/InputMonth.vue'),
    InputDate: () => import('@/components/general/InputDate.vue')
  },

  props: {
    items: {
      type: Object,
      default: () => {}
    },
    tucs: {
      type: Array,
      default: () => []
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.fillFields();
    } else {
      this.getUltimaDataBaseAIS();
      this.getUltimaDataBaseBDGD();
      this.getUltimaDataBaseLaudo();
    }
  },

  data() {
    return {
      campos: {
        titulo: '',
        tipoBaseContabil: 'AIS',
        monthAis: '',
        monthLaudo: '',
        monthBdgd: '',
        bdgdVersionId: null,
        dataImobInicio: '',
        dataImobFim: '',
        sobraFisica: false /* True = SITCONT (checked) */,
        bdgd: false /* False = SIG-R Completa, True = Apenas BDGD */,
        comparaTi: false,
        anual: true /* True = Comparar por ano (checked) */,
        tucs: [
          '125',
          '160',
          '210',
          '295',
          '340',
          '345',
          '565',
          '570',
          '575',
          '580'
        ],
        tucsSelected: [
          '125',
          '160',
          '210',
          '295',
          '340',
          '345',
          '565',
          '570',
          '575',
          '580'
        ],
        odis: ''
      }
    };
  },

  computed: {
    empresa() {
      return this.$store.getters.getSelectedCompany;
    },
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },

  methods: {
    fillFields() {
      let comparativo = this.items;

      this.campos = {
        titulo: comparativo.titulo,
        tipoBaseContabil: comparativo.tipo_base_contabil,
        bdgdVersionId: comparativo.bdgd_versao_id,
        monthBdgd: comparativo.data_bdgd
          ? this.formatMonthToPicker(comparativo.data_bdgd)
          : '',
        monthAis: comparativo.data_ais
          ? this.formatMonthToPicker(comparativo.data_ais)
          : '',
        monthLaudo: comparativo.data_laudo
          ? this.formatMonthToPicker(comparativo.data_laudo)
          : '',
        dataImobInicio: comparativo.data_imobilizacao_inicio
          ? this.formatDateToPicker(comparativo.data_imobilizacao_inicio)
          : '',
        dataImobFim: comparativo.data_imobilizacao_fim
          ? this.formatDateToPicker(comparativo.data_imobilizacao_fim)
          : '',
        sobraFisica: comparativo.sobra_fisica,
        bdgd: comparativo.bdgd,
        comparaTi: comparativo.compara_ti,
        anual: comparativo.anual,
        tucs: this.campos.tucs,
        tucsSelected: this.tucs ? this.tucs : [],
        odis: comparativo.odis ? this.formatTextAreaValue(comparativo.odis) : ''
      };
    },

    formatTextAreaValue(valueString) {
      return JSON.parse(valueString).join('\n');
    },

    getUltimaDataBaseAIS() {
      AisService.getUltimaDataBase(this.empresa.id)
        .then(({ data }) => {
          const [year, month] = data.split('-');
          this.campos.monthAis = data ? `${year}-${month}` : '';
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última data base do AIS.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },

    getUltimaDataBaseBDGD() {
      if (this.userSelectedCompanyBdgdVersion) {
        const { data_registro } = this.userSelectedCompanyBdgdVersion;
        this.campos.monthBdgd = dayjs(data_registro).format('YYYY-MM');
        return this.campos.monthBdgd;
      }
      BdgdVersionsService.getUltimaDataBase(this.empresa.id)
        .then(({ data }) => {
          const [year, month] = data.split('-');
          this.campos.monthBdgd = data ? `${year}-${month}` : '';
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última data base da BDGD.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },

    getUltimaDataBaseLaudo() {
      LaudosService.getUltimaDataBase(this.empresa.id)
        .then(({ data }) => {
          const [year, month] = data.split('-');
          this.campos.monthLaudo = data ? `${year}-${month}` : '';
        })
        .catch((err) => {
          this.$toast.error('Erro ao buscar a última data base do Laudo.', '', {
            position: 'topRight'
          });
          console.error('Erro:', err);
        });
    },

    exportData() {
      if (this.campos.bdgd) {
        /* IF para caso a opção 'Apenas BDGD' estiver marcada */
        this.campos.monthAis = null;
        this.campos.monthLaudo = null;
      }

      return {
        titulo: this.campos.titulo,
        tipo_base_contabil: this.campos.tipoBaseContabil,
        data_ais: this.campos.monthAis ? this.campos.monthAis + '-01' : null,
        data_laudo: this.campos.monthLaudo
          ? this.campos.monthLaudo + '-01'
          : null,
        data_bdgd: this.campos.monthBdgd + '-01',
        bdgd_versao_id: this.campos.bdgdVersionId,
        data_imobilizacao_inicio: this.campos.dataImobInicio
          ? this.campos.dataImobInicio
          : null,
        data_imobilizacao_fim: this.campos.dataImobFim
          ? this.campos.dataImobFim
          : null,
        sobra_fisica: this.campos.sobraFisica,
        bdgd: this.campos.bdgd,
        compara_ti: this.campos.comparaTi,
        anual: this.campos.anual,
        tucs: this.campos.tucsSelected.toString(),
        odis: this.campos.odis
      };
    },

    exportTypeTour() {
      return this.campos.bdgd
        ? 'apenas bdgd'
        : 'completa'; /* this.campos.bdgd == Apenas BDGD (checked) */
    }
  },

  watch: {
    items() {
      this.fillFields();
    }
  }
};
</script>

<style>
.spacing-checkbox-sig-r div div .v-input__slot {
  margin-bottom: -12px !important;
}
.v-alert__icon {
  align-self: center;
}
</style>
