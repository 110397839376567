var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tab-item',{staticClass:"pb-12"},[_c('div',{staticClass:"text-center display-1 font-weight-normal mb-6"},[_vm._v(" Vamos começar preenchendo as informações básicas ")]),_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-4 py-0 px-1",attrs:{"dense":"","outlined":"","type":"info","color":"blue"}},[_vm._v(" NÃO são utilizadas as TIs: 10 a 29, 70 a 92, 94, 95, 97 a 99. ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"titulo-comparativo","label":"Título","outlined":"","error-messages":errors},model:{value:(_vm.campos.titulo),callback:function ($$v) {_vm.$set(_vm.campos, "titulo", $$v)},expression:"campos.titulo"}})]}}])})],1)],1),_c('v-row',{staticClass:"mx-auto",attrs:{"id":"data-imobilizacao-comparativo"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('input-date',{attrs:{"label":"Data Imobilização","selectedDate":_vm.campos.dataImobInicio,"rules":{
            required_if:
              _vm.campos.dataImobFim != null && _vm.campos.dataImobFim.length > 0,
            min: 10
          }},on:{"dataSelected":function (data) { return (_vm.campos.dataImobInicio = data); }}})],1),_c('v-col',{staticClass:"text-center pt-6",staticStyle:{"min-width":"60px !important"},attrs:{"cols":"1"}},[_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.54)","font-size":"15px"}},[_vm._v("até")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('input-date',{attrs:{"label":"Data Imobilização","selectedDate":_vm.campos.dataImobFim,"rules":{
            required_if:
              _vm.campos.dataImobInicio != null &&
              _vm.campos.dataImobInicio.length > 0,
            min: 10
          }},on:{"dataSelected":function (data) { return (_vm.campos.dataImobFim = data); }}})],1)],1),_c('div',{attrs:{"id":"group-check-comparativo"}},[_c('v-row',{staticClass:"mx-auto spacing-checkbox-sig-r"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Comparar por ano","checked":""},model:{value:(_vm.campos.anual),callback:function ($$v) {_vm.$set(_vm.campos, "anual", $$v)},expression:"campos.anual"}})],1)],1),_c('v-row',{staticClass:"mx-auto spacing-checkbox-sig-r"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"label":"Comparar por TI"},model:{value:(_vm.campos.comparaTi),callback:function ($$v) {_vm.$set(_vm.campos, "comparaTi", $$v)},expression:"campos.comparaTi"}})],1)],1),_c('v-row',{staticClass:"mx-auto spacing-checkbox-sig-r"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"label":"Incluir Todas as Situações Contábeis (SITCONT)"},model:{value:(_vm.campos.sobraFisica),callback:function ($$v) {_vm.$set(_vm.campos, "sobraFisica", $$v)},expression:"campos.sobraFisica"}})],1)],1)],1)],1),_c('v-tab-item',{staticClass:"pb-0"},[_c('div',{staticClass:"text-center display-1 font-weight-normal mb-6"},[_vm._v(" Qual o tipo do SIG-R? ")]),_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center","id":"group-tipo-comparativo"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"pt-0 mt-0",attrs:{"error-messages":errors,"row":""},model:{value:(_vm.campos.bdgd),callback:function ($$v) {_vm.$set(_vm.campos, "bdgd", $$v)},expression:"campos.bdgd"}},[_c('v-radio',{attrs:{"label":"SIG-R Completa","value":false}}),_c('v-radio',{attrs:{"label":"Apenas BDGD","value":true}})],1)]}}])})],1)],1),_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center","id":"data-base-bdgd-ais-comparativo"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('input-month',{attrs:{"label":"Data Base BDGD","selectedMonth":_vm.campos.monthBdgd},on:{"monthSelected":function (month) { return (_vm.campos.monthBdgd = month); }}})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"5"}},[_c('select-bdgd-version',{attrs:{"label":"Versão BDGD","required":"","companyId":_vm.empresa.id,"month":_vm.campos.monthBdgd},on:{"bdgdVersionSelected":function (bdgdVersionId) { return (_vm.campos.bdgdVersionId = bdgdVersionId); }}})],1)],1),(!_vm.campos.bdgd)?_c('div',{staticClass:"text-center display-1 font-weight-normal mb-6"},[_vm._v(" Qual base contábil será comparada com a BDGD? ")]):_vm._e(),(!_vm.campos.bdgd)?_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center","id":"tipo-base-contabil"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"pt-0 mt-0",attrs:{"error-messages":errors,"row":""},model:{value:(_vm.campos.tipoBaseContabil),callback:function ($$v) {_vm.$set(_vm.campos, "tipoBaseContabil", $$v)},expression:"campos.tipoBaseContabil"}},[_c('v-radio',{attrs:{"label":"AIS","value":"AIS"}}),_c('v-radio',{attrs:{"label":"Laudo","value":"LAUDO"}})],1)]}}],null,false,3129980440)})],1)],1):_vm._e(),(!_vm.campos.bdgd && _vm.campos.tipoBaseContabil === 'AIS')?_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('input-month',{attrs:{"label":"Data Base AIS","selectedMonth":_vm.campos.monthAis},on:{"monthSelected":function (month) { return (_vm.campos.monthAis = month); }}})],1),_c('v-col',{attrs:{"cols":"7"}})],1):_vm._e(),(!_vm.campos.bdgd && _vm.campos.tipoBaseContabil === 'LAUDO')?_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('input-month',{attrs:{"label":"Data Base Laudo","selectedMonth":_vm.campos.monthLaudo},on:{"monthSelected":function (month) { return (_vm.campos.monthLaudo = month); }}})],1),_c('v-col',{attrs:{"cols":"7"}})],1):_vm._e()],1),_c('v-tab-item',{staticClass:"pb-12"},[_c('div',{staticClass:"text-center display-1 font-weight-normal mb-6"},[_vm._v(" Para finalizar, preencha as TUCs e ODIs para comparação ")]),_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center","id":"tucs-comparativo"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.campos.tucs,"item-value":"id","item-text":"nome","label":"TUCs","multiple":"","outlined":"","chips":""},model:{value:(_vm.campos.tucsSelected),callback:function ($$v) {_vm.$set(_vm.campos, "tucsSelected", $$v)},expression:"campos.tucsSelected"}})]}}])})],1)],1),_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center","id":"odis-comparativo"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"ODis","placeholder":"Insira os códigos das ODIs, um código por linha","rows":"5","no-resize":"","clearable":"","clear-icon":"mdi-close-circle"},model:{value:(_vm.campos.odis),callback:function ($$v) {_vm.$set(_vm.campos, "odis", $$v)},expression:"campos.odis"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }